import { Link } from "gatsby";
import React from "react";

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ?
    { className: "active" } :
    { className: "" };
};

const PartialNavLink = (props) => (
  <Link getProps={isPartiallyActive} {...props}>
    {props.children}
  </Link>
);

const Navigation = () => (
  <ul>
    <li><Link to="/" activeClassName='active'>Home</Link></li>
    {/* <li><PartialNavLink to="/blog" activeClassName='active'>Blog</PartialNavLink></li> */}
    {/* <li><PartialNavLink to="/contact" activeClassName='active'>Contact</PartialNavLink></li> */}
    <li><PartialNavLink to="/camp" activeClassName='active'>Camp</PartialNavLink></li>
  </ul>
);

export default Navigation;
