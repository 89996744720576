import "../styles/application.scss";
import { graphql, StaticQuery } from "gatsby";
import Footer from "./footer/footer";
import Header from "./header/header";
import PropTypes from "prop-types";
import React from "react";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <React.Fragment>
        <Header siteTitle={data.site.siteMetadata.title} />

        {children}

        <Footer siteTitle={data.site.siteMetadata.title} />
      </React.Fragment>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
