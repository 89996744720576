import { faFacebookSquare, faInstagram, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const SocialLinks = () => (
  <ul className="list--horizontal">
    <li>
      <a href="https://www.facebook.com/groups/1805008776379670/" className="link link--white" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon size="2x" icon={faFacebookSquare} />
      </a>
    </li>
    {/* <li>
      <a href="https://www.twitter.com" className="link link--white">
        <FontAwesomeIcon size="2x" icon={faTwitterSquare} />
      </a>
    </li> */}
    {/* <li>
      <a href="https://www.instagram.com" className="link link--white">
        <FontAwesomeIcon size="2x" icon={faInstagram} />
      </a>
    </li> */}
  </ul>
);

export default SocialLinks;
