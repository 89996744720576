import { Link } from "gatsby";
import { slide as Menu } from "react-burger-menu";
import MobileStyles from "../../styles/vendor/mobilestyles.scss";
import React from "react";

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ?
    { className: "bm-link bm-active" } :
    { className: "bm-link" };
};

const PartialNavLink = (props) => (
  <Link getProps={isPartiallyActive} {...props}>
    {props.children}
  </Link>
);

class MobileMenu extends React.Component {

  render() {
    return (
      <nav className='hidden--desktop hidden--tablet'>
        <Menu
          right={true}
          styles={MobileStyles}
          customBurgerIcon={
            <span>
              <button className="button--blue" type="button">Menu</button>
            </span>
          }
          customCrossIcon={
            <span>
              <button className="button--blue" type="button">Close</button>
            </span>
          }
        >
          <Link to="/" id='home' className='bm-link' activeClassName='bm-active'>Home</Link>
          {/* <PartialNavLink to="/blog" id='blog' className='bm-link' activeClassName='bm-active'>Blog</PartialNavLink> */}
          {/* <PartialNavLink to="/contact" id='contact' className='bm-link' activeClassName='bm-active'>Contact Us</PartialNavLink> */}
          <PartialNavLink to="/camp" id='camp' className='bm-link' activeClassName='bm-active'>Camp</PartialNavLink>
        </Menu>
      </nav>
    );
  }

  showSettings(event) {
    event.preventDefault();
  }

}

export default MobileMenu;
