import { Link } from "gatsby";
import MobileMenu from "../navigation/mobilemenu";
import Navigation from "../navigation/navigation";
import PropTypes from "prop-types";
import React from "react";

const Header = ({ siteTitle }) => (
  <React.Fragment>
    <header className="header">
      <span className="header-logo">
        <Link className="header-logo-size flex" to="/">
          { siteTitle }
        </Link>
      </span>
      <nav className="header-nav hidden--mobile">
        <Navigation />
      </nav>
    </header>
    <MobileMenu />
  </React.Fragment>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ""
};

export default Header;
