import { Link } from "gatsby";
import Navigation from "../navigation/navigation";
import PropTypes from "prop-types";
import React from "react";
import SocialLinks from "../navigation/social";
import { year } from "../utility/date";

const Footer = ({ siteTitle }) => (
  <footer className="footer">
    <div className="footer-width">
      <div className="grid grid-padding grid-wrapper">

        <div className="grid--12 footer--navigation">
          <h1 className="no-margin--top">{siteTitle}</h1>
          <Navigation />
          <SocialLinks />
        </div>

        <div className="grid--12">
          <p>&copy; { siteTitle } 2018-{ year }
        &nbsp;
        &#124; <Link className="link link--white" to="/">charitygracebiblechurch.org</Link>
        &nbsp;
        &#124; All Rights Reserved
          </p>
        </div>

      </div>
    </div>
  </footer>
);

export default Footer;

Footer.propTypes = {
  siteTitle: PropTypes.string
};

Footer.defaultProps = {
  siteTitle: ""
};
